.project {
    width: 100vw;
    text-align: center;
}

.screenshots {
    width: 100vw;
    border-radius: 3%;
}

.Runik:hover {
    content: url(../images/RunikHover.png);
}

.Abyssal:hover {
    content: url(../images/AbyssalHover.png);
}

.Redesign:hover {
    content: url(../images/AbyssalRedesignHover.png);
}

.VXP:hover {
    content: url(../images/VXPHover.png);
}

.Express:hover {
    content: url(../images/ExpressHover.png);
}

.Proposal:hover {
    content: url(../images/ProposalHover.png);
}

.description {
    text-align: left;
}

.technologies {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.technologies p {
    margin-top: 0;
    margin-right: 1.2rem;
    font-style: italic;
    font-size: 1.5rem;
}

.information {
    width: 40px;
    margin: 1rem;
    border-radius: 30%;
    overflow: hidden;
	box-shadow: 0 5px 15px 0px #FAFAFF;
	transform: translatey(0px);
	animation: float 6s ease-in-out infinite;
}

.information:hover {
    animation: sway 2s infinite;
}

.information .gitHub {
    border-bottom-left-radius: 40%;
    border-bottom-right-radius: 40%;
}

@keyframes float {
	0% {
		box-shadow: 0 5px 10px 0px #C4B9C7;
		transform: translatey(0px);
	}
	50% {
		box-shadow: 0 15px 10px 0px #C4B9C7;
		transform: translatey(-20px);
	}
	100% {
		box-shadow: 0 5px 10px 0px #C4B9C7;
		transform: translatey(0px);
	}
}

@keyframes sway {
    0% {transform: rotate(8deg);}
    50% {transform: rotate(-5deg);}
    100%{transform: rotate(8deg);}
}

@media screen and (min-width: 600px) {
    .project {
        width: 600px;
    }
    
    .screenshots {
        width: 100%;
    }

}