@import url(https://fonts.googleapis.com/css2?family=Pacifico&family=Patrick+Hand&family=Pattaya&family=Peddana&display=swap);
body {
  margin: 0;
  padding: 0;
  background: linear-gradient(180deg, #F8DDC5, #E9DBCF, #D8D9DA, #CCD7E2);
  background-size: 2000% 3000%;
  -webkit-animation: gradient 20s ease-in-out infinite;
          animation: gradient 20s ease-in-out infinite;
}

@-webkit-keyframes gradient {
	0% {
		background-position: 0% 30%;
	}
	10% {
		background-position: 30% 60%;
	}
	20% {
		background-position: 60% 90%;
	}
	40% {
		background-position: 90% 100%;
	}
	50% {
		background-position: 100% 100%;
	}
	60% {
		background-position: 100% 90%;
	}
	80% {
		background-position: 90% 60%;
	}
	90% {
		background-position: 60% 30%;
	}
	100% {
		background-position: 30% 0%;
	}
}

@keyframes gradient {
	0% {
		background-position: 0% 30%;
	}
	10% {
		background-position: 30% 60%;
	}
	20% {
		background-position: 60% 90%;
	}
	40% {
		background-position: 90% 100%;
	}
	50% {
		background-position: 100% 100%;
	}
	60% {
		background-position: 100% 90%;
	}
	80% {
		background-position: 90% 60%;
	}
	90% {
		background-position: 60% 30%;
	}
	100% {
		background-position: 30% 0%;
	}
}

#navigation {
    position: fixed;
    display: flex;
    justify-content: center;
    width: 100vw;
    z-index: 1;
}

#navigation a {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    font-size: 1rem;
    -webkit-animation: change 10s ease infinite;
            animation: change 10s ease infinite;

}

#navigation a:hover {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='squiggle-link' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:ev='http://www.w3.org/2001/xml-events' viewBox='0 0 20 4'%3E%3Cstyle type='text/css'%3E.squiggle{animation:shift .3s linear infinite;}@keyframes shift {from {transform:translateX(0);}to {transform:translateX(-20px);}}%3C/style%3E%3Cpath fill='none' stroke='%238490A8' stroke-width='2' class='squiggle' d='M0,3.5 c 5,0,5,-3,10,-3 s 5,3,10,3 c 5,0,5,-3,10,-3 s 5,3,10,3'/%3E%3C/svg%3E");
    background-position: 0 100%;
    background-size: auto 6px;
    background-repeat: repeat-x;
    text-decoration: none;
}

#navigation #menuResume {
    background-image: none;
    padding-left: 1em;
    padding-right: 1em;
    color: #E26E55;
    background-color: #E2C894;
    text-align: center;
    border-radius: 10rem;
    -webkit-animation: float 6s ease-in-out infinite;
            animation: float 6s ease-in-out infinite;
}

#navigation #menuResume:hover {
    background-color: #cad0dd;
}

#menuResume {
    visibility: hidden;
}

@-webkit-keyframes change {
    0% {
		color: #7E2915;
	}
    25% {
        color: #5F3930;
    }
    50% {
        color: #4F5888;
    }
    75% {
		color: #23414D;
	}
    100% {
        color: #5F3930;
    }
}

@keyframes change {
    0% {
		color: #7E2915;
	}
    25% {
        color: #5F3930;
    }
    50% {
        color: #4F5888;
    }
    75% {
		color: #23414D;
	}
    100% {
        color: #5F3930;
    }
}

@-webkit-keyframes float {
	0% {
		box-shadow: 0 5px 10px 0px #C4B9C7;
		-webkit-transform: translatey(0px);
		        transform: translatey(0px);
	}
	50% {
		box-shadow: 0 15px 10px 0px #C4B9C7;
		-webkit-transform: translatey(-20px);
		        transform: translatey(-20px);
	}
	100% {
		box-shadow: 0 5px 10px 0px #C4B9C7;
		-webkit-transform: translatey(0px);
		        transform: translatey(0px);
	}
}

@keyframes float {
	0% {
		box-shadow: 0 5px 10px 0px #C4B9C7;
		-webkit-transform: translatey(0px);
		        transform: translatey(0px);
	}
	50% {
		box-shadow: 0 15px 10px 0px #C4B9C7;
		-webkit-transform: translatey(-20px);
		        transform: translatey(-20px);
	}
	100% {
		box-shadow: 0 5px 10px 0px #C4B9C7;
		-webkit-transform: translatey(0px);
		        transform: translatey(0px);
	}
}

@media only screen and (min-width: 700px) {
  #navigation a {
    font-size: 1.5rem;
  }

  #menuResume {
      visibility: visible;
  }
}

@media only screen and (min-width: 1000px) {
  #navigation a {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    font-size: 1.8rem;
  }
}

html {
    scroll-behavior: smooth;
}

html, body {
    overflow-x: hidden;
}

/* Scrollbar Behavior */
body::-webkit-scrollbar {
    width: 1em;
}
   
body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #FFFFFF;
}
   
body::-webkit-scrollbar-thumb {
    background-color: #E4B5B5;
    outline: 1px solid #E4B5B5;
}

:focus {
    outline-color: #E26E55;
}

h1 {
    font-family: 'Pattaya', sans-serif;
    font-size: 3rem;
    text-align: center;
    color: #59515C;
    -webkit-animation-name: movement;
            animation-name: movement;
    -webkit-animation-duration: 3s;
            animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-direction: alternate;
            animation-direction: alternate;
}

h2 {
    font-family: 'Pacifico', cursive;
    font-size: 1.5rem;
    text-align: center;
    color: #5E4444;
    text-decoration: underline;
    -webkit-text-decoration-style: dotted;
            text-decoration-style: dotted;
    -webkit-text-decoration-color: white;
            text-decoration-color: white;
}

p {
    font-family: 'Patrick Hand', cursive;
    color: #59515C;
    font-size: 1.5rem;
}

a {
    font-family: 'Pacifico', cursive;
    text-decoration: none;
    font-size: 1.5rem;
}

ul {
    list-style-type: none;
}


@-webkit-keyframes change {
    0% {
		color: #7E2915;
	}
    25% {
        color: #5F3930;
    }
    50% {
        color: #4F5888;
    }
    75% {
		color: #23414D;
	}
    100% {
        color: #5F3930;
    }
}


@keyframes change {
    0% {
		color: #7E2915;
	}
    25% {
        color: #5F3930;
    }
    50% {
        color: #4F5888;
    }
    75% {
		color: #23414D;
	}
    100% {
        color: #5F3930;
    }
}

@-webkit-keyframes movement {
    from {
     -webkit-transform: skewX(20deg);
             transform: skewX(20deg);
    }
    
    to {
     -webkit-transform: skewX(-2-deg);
             transform: skewX(-2-deg);
    }
}

@keyframes movement {
    from {
     -webkit-transform: skewX(20deg);
             transform: skewX(20deg);
    }
    
    to {
     -webkit-transform: skewX(-2-deg);
             transform: skewX(-2-deg);
    }
}

@media screen and (min-width: 500px) {
    h1 {
        font-size: 6rem;
    }

    h2 {
        font-size: 3.5rem;
    }

    p {
        font-size: 1.8rem;
    }
}
#sideNavigation  {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100vh - 107px);
    z-index: 1;
    visibility: hidden;
}

#sideNavigation img {
    width: 30px;
    margin-bottom: 1rem;
    border-radius: 25%;
    overflow: hidden;
	box-shadow: 0 5px 15px 0px #FAFAFF;
	-webkit-transform: translatey(0px);
	        transform: translatey(0px);
	-webkit-animation: float 6s ease-in-out infinite;
	        animation: float 6s ease-in-out infinite;
}

#sideNavigation #gitHub {
    border-bottom-left-radius: 40%;
    border-bottom-right-radius: 40%;
}

#sideNavigation img:hover {
    -webkit-animation: sway 2s infinite;
            animation: sway 2s infinite;
}

@-webkit-keyframes float {
	0% {
		box-shadow: 0 5px 10px 0px #C4B9C7;
		-webkit-transform: translatey(0px);
		        transform: translatey(0px);
	}
	50% {
		box-shadow: 0 15px 10px 0px #C4B9C7;
		-webkit-transform: translatey(-20px);
		        transform: translatey(-20px);
	}
	100% {
		box-shadow: 0 5px 10px 0px #C4B9C7;
		-webkit-transform: translatey(0px);
		        transform: translatey(0px);
	}
}

@keyframes float {
	0% {
		box-shadow: 0 5px 10px 0px #C4B9C7;
		-webkit-transform: translatey(0px);
		        transform: translatey(0px);
	}
	50% {
		box-shadow: 0 15px 10px 0px #C4B9C7;
		-webkit-transform: translatey(-20px);
		        transform: translatey(-20px);
	}
	100% {
		box-shadow: 0 5px 10px 0px #C4B9C7;
		-webkit-transform: translatey(0px);
		        transform: translatey(0px);
	}
}

@-webkit-keyframes sway {
    0% {-webkit-transform: rotate(8deg);transform: rotate(8deg);}
    50% {-webkit-transform: rotate(-5deg);transform: rotate(-5deg);}
    100%{-webkit-transform: rotate(8deg);transform: rotate(8deg);}
}

@keyframes sway {
    0% {-webkit-transform: rotate(8deg);transform: rotate(8deg);}
    50% {-webkit-transform: rotate(-5deg);transform: rotate(-5deg);}
    100%{-webkit-transform: rotate(8deg);transform: rotate(8deg);}
}

@media screen and (min-width: 700px) {
    #sideNavigation {
        visibility: visible;
    }
}

@media screen and (min-width: 1000px) {
    #sideNavigation img {
        width: 40px;
    }
}
#hero {
    display: flex;
    justify-content: center;
    background-image: url(/static/media/LogoBackground.134d5595.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100vh;

}

#logo {
    width: 50%;
}

#about {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20rem;
}

#about p {
    font-size: 1.5rem;
    text-align: center;
}

.handwritten, .role {
    margin-left: 2rem;
    margin-right: 2rem;
    font-family: 'Pacifico', cursive;
    color: #5E4444;
    -webkit-text-decoration: underline dotted #FFFFFF;
            text-decoration: underline dotted #FFFFFF;
}

#about a{
    padding-right: 1em;
    padding-left: 1em;
    color: #E26E55;
    background-color: #E2C894;
    text-align: center;
    font-size: 2rem;
    border-radius: 5rem;
    -webkit-animation: float 6s ease-in-out infinite;
            animation: float 6s ease-in-out infinite;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

#about a:hover {
    background-color: #cad0dd;
}

@-webkit-keyframes float {
	0% {
		box-shadow: 0 5px 10px 0px #C4B9C7;
		-webkit-transform: translatey(0px);
		        transform: translatey(0px);
	}
	50% {
		box-shadow: 0 15px 10px 0px #C4B9C7;
		-webkit-transform: translatey(-10px);
		        transform: translatey(-10px);
	}
	100% {
		box-shadow: 0 5px 10px 0px #C4B9C7;
		-webkit-transform: translatey(0px);
		        transform: translatey(0px);
	}
}

@keyframes float {
	0% {
		box-shadow: 0 5px 10px 0px #C4B9C7;
		-webkit-transform: translatey(0px);
		        transform: translatey(0px);
	}
	50% {
		box-shadow: 0 15px 10px 0px #C4B9C7;
		-webkit-transform: translatey(-10px);
		        transform: translatey(-10px);
	}
	100% {
		box-shadow: 0 5px 10px 0px #C4B9C7;
		-webkit-transform: translatey(0px);
		        transform: translatey(0px);
	}
}

@media screen and (min-width: 500px) {
    #about p {
        font-size: 3.5rem;
    }

    #about a {
        font-size: 4rem;
    }
}
#projects {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.background {
    position: absolute;
    top: 0;
    z-index: -1;
    height: 600vh;
}
.project {
    width: 100vw;
    text-align: center;
}

.screenshots {
    width: 100vw;
    border-radius: 3%;
}

.Runik:hover {
    content: url(/static/media/RunikHover.d805e456.png);
}

.Abyssal:hover {
    content: url(/static/media/AbyssalHover.b1d1f49c.png);
}

.Redesign:hover {
    content: url(/static/media/AbyssalRedesignHover.b3572702.png);
}

.VXP:hover {
    content: url(/static/media/VXPHover.6579fe2a.png);
}

.Express:hover {
    content: url(/static/media/ExpressHover.ef9f8c5d.png);
}

.Proposal:hover {
    content: url(/static/media/ProposalHover.f59dd79f.png);
}

.description {
    text-align: left;
}

.technologies {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.technologies p {
    margin-top: 0;
    margin-right: 1.2rem;
    font-style: italic;
    font-size: 1.5rem;
}

.information {
    width: 40px;
    margin: 1rem;
    border-radius: 30%;
    overflow: hidden;
	box-shadow: 0 5px 15px 0px #FAFAFF;
	-webkit-transform: translatey(0px);
	        transform: translatey(0px);
	-webkit-animation: float 6s ease-in-out infinite;
	        animation: float 6s ease-in-out infinite;
}

.information:hover {
    -webkit-animation: sway 2s infinite;
            animation: sway 2s infinite;
}

.information .gitHub {
    border-bottom-left-radius: 40%;
    border-bottom-right-radius: 40%;
}

@-webkit-keyframes float {
	0% {
		box-shadow: 0 5px 10px 0px #C4B9C7;
		-webkit-transform: translatey(0px);
		        transform: translatey(0px);
	}
	50% {
		box-shadow: 0 15px 10px 0px #C4B9C7;
		-webkit-transform: translatey(-20px);
		        transform: translatey(-20px);
	}
	100% {
		box-shadow: 0 5px 10px 0px #C4B9C7;
		-webkit-transform: translatey(0px);
		        transform: translatey(0px);
	}
}

@keyframes float {
	0% {
		box-shadow: 0 5px 10px 0px #C4B9C7;
		-webkit-transform: translatey(0px);
		        transform: translatey(0px);
	}
	50% {
		box-shadow: 0 15px 10px 0px #C4B9C7;
		-webkit-transform: translatey(-20px);
		        transform: translatey(-20px);
	}
	100% {
		box-shadow: 0 5px 10px 0px #C4B9C7;
		-webkit-transform: translatey(0px);
		        transform: translatey(0px);
	}
}

@-webkit-keyframes sway {
    0% {-webkit-transform: rotate(8deg);transform: rotate(8deg);}
    50% {-webkit-transform: rotate(-5deg);transform: rotate(-5deg);}
    100%{-webkit-transform: rotate(8deg);transform: rotate(8deg);}
}

@keyframes sway {
    0% {-webkit-transform: rotate(8deg);transform: rotate(8deg);}
    50% {-webkit-transform: rotate(-5deg);transform: rotate(-5deg);}
    100%{-webkit-transform: rotate(8deg);transform: rotate(8deg);}
}

@media screen and (min-width: 600px) {
    .project {
        width: 600px;
    }
    
    .screenshots {
        width: 100%;
    }

}
#footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

#footerBackground {
    position: absolute;
    opacity: 30%;
    z-index: -1;
    overflow: hidden
}

#thanks {
    margin-top: 25rem;
    margin-bottom: 0;
}

.copyright {
    margin-top: 15rem;
}
