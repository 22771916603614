body {
  margin: 0;
  padding: 0;
  background: linear-gradient(180deg, #F8DDC5, #E9DBCF, #D8D9DA, #CCD7E2);
  background-size: 2000% 3000%;
  animation: gradient 20s ease-in-out infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 30%;
	}
	10% {
		background-position: 30% 60%;
	}
	20% {
		background-position: 60% 90%;
	}
	40% {
		background-position: 90% 100%;
	}
	50% {
		background-position: 100% 100%;
	}
	60% {
		background-position: 100% 90%;
	}
	80% {
		background-position: 90% 60%;
	}
	90% {
		background-position: 60% 30%;
	}
	100% {
		background-position: 30% 0%;
	}
}
