#hero {
    display: flex;
    justify-content: center;
    background-image: url("../images/LogoBackground.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100vh;

}

#logo {
    width: 50%;
}
