#projects {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.background {
    position: absolute;
    top: 0;
    z-index: -1;
    height: 600vh;
}