#about {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20rem;
}

#about p {
    font-size: 1.5rem;
    text-align: center;
}

.handwritten, .role {
    margin-left: 2rem;
    margin-right: 2rem;
    font-family: 'Pacifico', cursive;
    color: #5E4444;
    text-decoration: underline dotted #FFFFFF;
}

#about a{
    padding-right: 1em;
    padding-left: 1em;
    color: #E26E55;
    background-color: #E2C894;
    text-align: center;
    font-size: 2rem;
    border-radius: 5rem;
    animation: float 6s ease-in-out infinite;
    width: fit-content;
}

#about a:hover {
    background-color: #cad0dd;
}

@keyframes float {
	0% {
		box-shadow: 0 5px 10px 0px #C4B9C7;
		transform: translatey(0px);
	}
	50% {
		box-shadow: 0 15px 10px 0px #C4B9C7;
		transform: translatey(-10px);
	}
	100% {
		box-shadow: 0 5px 10px 0px #C4B9C7;
		transform: translatey(0px);
	}
}

@media screen and (min-width: 500px) {
    #about p {
        font-size: 3.5rem;
    }

    #about a {
        font-size: 4rem;
    }
}